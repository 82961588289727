<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- Product Image -->
        <template v-if="dataImg">

          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="dataImg" alt="img" class="responsive">
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Update Image</vs-button>
            <vs-button type="flat" color="#999" @click="dataImg = null">Remove Image</vs-button>
          </div>
        </template>

        <!-- NAME -->
        <vs-input label="Name" v-model="name" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('item-name')">{{ errors.first('item-name') }}</span>

        <!-- CATEGORY -->
        <!-- <vs-select v-model="dataCategory" label="Category" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in category_choices" />
        </vs-select>
        <span class="text-danger text-sm" v-show="errors.has('item-category')">{{ errors.first('item-category') }}</span> -->

        <!-- CATEGORY -->
        <!-- <vs-select v-model="dataCategory" label="Category" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in category_choices" />
        </vs-select>
        <span class="text-danger text-sm" v-show="errors.has('item-category')">{{ errors.first('item-category') }}</span> -->

        <!-- ORDER STATUS -->
        <!-- <vs-select v-model="dataOrder_status" label="Order Status" class="mt-5 w-full">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in order_status_choices" />
        </vs-select> -->
  
  <div class="flex -mx-2">
    <div class="w-1/2 px-2">
      <vs-input v-validate="{ required: true, regex: /\d+(\.\d+)?$/ }" class="mt-5 w-full" label="Physical height" v-model="height" name="height" />
    </div>
    <br>
        <br>

    <div class="w-1/2 px-2">
              <ul  class= "margin-top mt-5 w-full leftx">
    <li>
      <vs-radio v-model="unit" vs-name="radios1" vs-value="cm">cm</vs-radio>
    </li>
    <li>
      <vs-radio v-model="unit" vs-name="radios1" vs-value="inches">inches</vs-radio>
    </li>
  </ul>
      <!-- <vs-select class="mt-5 w-full" v-model="unit" label="Unit">
      <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in unitOptions" />
      </vs-select>       -->
    </div>
  </div>
  <span class="text-danger text-sm" v-show="errors.has('item-height')">{{ errors.first('item-height') }}</span>
<br>
  <div class="">
    <ul class="centerx">
      <li>
        <vs-checkbox v-model="virtualFrame">Virtual Frame</vs-checkbox>
      </li>
    </ul>
  </div>

  <!-- <vs-select class="w-1/2 mt-5" v-model="virtualFrame" label="Virtual Frame"> 
    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in virtualFrameOptions" />
  </vs-select>   -->
</div>

        <!-- Upload -->
        <!-- <vs-upload text="Upload Image" class="img-upload" ref="fileUpload" /> -->

        <!-- <div class="upload-img mt-5" v-if="!dataImg">
          <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button @click="$refs.uploadImgInput.click()">Upload Image</vs-button>
        </div> -->
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {

      pid: null,
      name: '',
      height :'',
      abilities: '',
      dataCategory: null,
      dataImg: null,
      dataOrder_status: 'pending',
      dataPrice: 0,
      unit: 'cm',
      returnUrl: '',
      unitOptions: [
        { text: 'cm', value: 'cm' },
        { text: 'inches', value: 'inches' }
      ],
      virtualFrame: false,
      virtualFrameOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' }
      ],
      category_choices: [
        {text:'Audio', value:'audio'},
        {text:'Computers', value:'computers'},
        {text:'Fitness', value:'fitness'},
        {text:'Appliance', value:'appliance'}
      ],

      order_status_choices: [
        {text:'Pending', value:'pending'},
        {text:'Canceled', value:'canceled'},
        {text:'Delivered', value:'delivered'},
        {text:'On Hold', value:'on_hold'}
      ],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      this.unit = "cm"

      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        console.log('hehehe')
        const { category, abilities, pid, img, name, order_status, price } = JSON.parse(JSON.stringify(this.data))
        console.log(pid)
        let height = ''
        try {
          height = JSON.parse(abilities).skin.height * 100
        } catch (error) {
          console.log(error)
          height = 100
        }

        
        console.log(JSON.parse(abilities).frame)
        try {
          const virtualFrame = JSON.parse(abilities).frame
          if (virtualFrame !== undefined) this.virtualFrame = true
          else this.virtualFrame = false
        } catch (error) {
          this.virtualFrame = false
          console.log(error)
          height = 1
        }

        this.pid = pid
        this.name = name
        this.height = height
        this.abilities = JSON.parse(abilities)
        this.dataCategory = category
        this.dataImg = img
        this.dataOrder_status = order_status
        this.dataPrice = price

        //this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.pid, this.name, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid () {
      return !this.errors.any() && this.name && this.pid && this.height > 0
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    initValues () {
      if (this.data.id) return
      this.pid = null
      this.name = ''
      this.dataCategory = null
      this.dataOrder_status = 'pending'
      this.dataPrice = 0
      this.dataImg = null
    },
    submitData () {
      this.$validator.validateAll().then(result => {

        let computedHeight = ''
        this.height = parseFloat(this.height)
        if (isNaN(this.height)) {
          computedHeight = 1
        } else if (this.unit === 'inches') {
          computedHeight = this.height * 0.0254
        } else {
          computedHeight = this.height / 100
        }

              // Modify Framed attributes
            try {
              if (this.virtualFrame)
                this.abilities.frame = {}
              else
                delete this.abilities.frame
          console.log(JSON.stringify(this.abilities))

        } catch (error) {
          console.log(error)
          // height = 1
        }

        // Modify Height Value
        try {
          this.abilities.skin.height = computedHeight
          console.log(JSON.stringify(this.abilities))

        } catch (error) {
          console.log(error)
          // height = 1
        }
        
        if (result) {
          const obj = {
            pid: this.pid,
            name: this.name,
            abilities: JSON.stringify(this.abilities),
            height: this.computedHeight

            // img: this.dataImg,
            // category: this.dataCategory,
            // order_status: this.dataOrder_status,
            // price: this.dataPrice
          }
          console.log(this.pid)
          console.log('this.pid')

          if (this.pid !== null) {
            this.$store.dispatch('dataList/updateHobject', obj).then(() => {
              this.$vs.loading.close()
              this.$vs.notify({text:'Artwork updated', color:'success', icon:'check_box'})
              console.log('SUCCESS')
            })
              .catch(err => { 
                this.$vs.loading.close()
                console.error(err) 
              })
          } else {
            delete obj.id
            obj.popularity = 0
            this.$store.dispatch('dataList/addItem', obj).catch(err => { console.error(err) })
          }

          this.$emit('closeSidebar')
          this.initValues()
        }
      })
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.margin-top {
  margin-top: 25px;
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
